import React from 'react';
import Service from '../components/Service/Service';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import WorkAlbum from '../components/WorkAlbum/WorkAlbum';
import ServicePageQuotation from '../components/ServicePageQuotation/ServicePageQuotation';
const ServicePage = () => {
  return (
    <div>
      <Navbar/>
      <Service/>
      <WorkAlbum/>
      <ServicePageQuotation/>
      <Footer/>
    </div>
  )
}

export default ServicePage
