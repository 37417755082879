import React from 'react';
import './Portfoliocard.css';
// import iconImage from '../../assets/icon-park-solid_left.svg'; 
import iconImage from '../../assets/icons/iconoir_transition-right-solid (1).svg'

const PortfolioCard = ({ leftImage, rightImage, address, onClick }) => {
  return (
    <div className="portfolio-card" onClick={onClick}>
      <div className="image-container-protfolio">
        <img src={leftImage} alt="Before" className="left-image" />
        <div className="icon-container">
          <img src={iconImage} alt="Icon" className="center-icon" />
        </div>
        <img src={rightImage} alt="After" className="right-image" />
      </div>
      <div className="address-container">
        <p>{address}</p>
      </div>
    </div>
  );
};
export default PortfolioCard;
