import React from 'react';
import './QualityMTrustedP.css';
import PartnershipCard from '../PartnershipCard/PartnershipCard';

import image20 from '../../assets/PartnerShip/image 20.png';   
import image21 from '../../assets/PartnerShip/image 21.png';   
import image22 from '../../assets/PartnerShip/image 22.png';   
import image23 from '../../assets/PartnerShip/image 23.png';   
import image24 from '../../assets/PartnerShip/image 24.png';   
import image25 from '../../assets/PartnerShip/image 25.png';   

const QualityMTrustedP = () => {
  return (
    <div className="QTcontainer">
      <div className="QTSection">
        <h2>Quality Materials and Trusted Partnerships</h2>
        <p>
          At Mahadev Enterprise, we believe in using only the highest quality materials from reputable brands. Our trusted partnerships ensure that every project is built to last, providing our clients with peace of mind and enduring value.
        </p>
        <div className="Partnership">
          <PartnershipCard Image={image20} />
          <PartnershipCard Image={image21} />
          <PartnershipCard Image={image22} />
          <PartnershipCard Image={image23} />
          <PartnershipCard Image={image24} />
          <PartnershipCard Image={image25} />
        </div>
      </div>
    </div>
  );
}

export default QualityMTrustedP;
