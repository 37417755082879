// import React from 'react'
// import './ServicesCard.css'

// const ServicesCard = ({ image, text }) => {
//   return (
//     <div className="card">
//       <img src={image} alt={text} className="card-image" />
//       <div className="card-text">{text}</div>
//     </div>
//   )
// }

// export default ServicesCard
// ServicesCard.js
import React from 'react';
import './ServicesCard.css';

const ServicesCard = ({ image, text, onClick }) => {
  return (
    <div className="card" onClick={() => onClick(image, text)}>
      <img src={image} alt={text} className="card-image" />
      <div className="card-text">{text}</div>
    </div>
  );
};

export default ServicesCard;
