import React, { useState, useEffect, useRef } from 'react';
import './Slider.css';
import slider_image_1 from '../../assets/slider_image_1.png';
import slider_image_2 from '../../assets/slider_image_2.png';
import slider_image_3 from '../../assets/slider_image_3.png';
import slider_image_4 from '../../assets/slider_image_4.png';
import iconleft from '../../assets/icon-park-solid_left-c.svg';
import iconright from '../../assets/icon-park-solid_right-c.svg';
import { Link } from 'react-router-dom';
const Slider = () => {
  const slides = [
    {
      image: slider_image_1,
      heading: "Trusted Craftsman",
      text: "We Don't Build Homes, We Craft Dreams",
      spanWords: ["Homes", "Dreams"]
    },
    {
      image: slider_image_2,
      heading: "We Care",
      text: "we know the Value, we Care, we build Relation for life",
      spanWords: ["Value", "Relation"]
    },
    {
      image: slider_image_3,
      heading: "Skilled & Committed",
      text: "we are committed for Timely Delivery",
      spanWords: ["Timely", "Delivery"]
    },
    {
      image: slider_image_4,
      heading: "Perfectionist",
      text: "We plan for perfection",
      spanWords: ["perfection"]
    }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);
  const delay = 3000;
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setCurrentIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [currentIndex]);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };
  const spanText = (text, spanWords) => {
    const regex = new RegExp(`(${spanWords.join('|')})`, 'gi');
    return text.split(regex).map((part, index) => {
      if (spanWords.includes(part)) {
        return <span key={index} className="highlight">{part}</span>;
      }
      return part;
    });
  };
  return (
    <div className="slider_container">
      <div
        className="slides"
        style={{ transform: `translateX(${-currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            className="slide"
            key={index}
            style={{ backgroundImage: `url(${slide.image})` }}
          />
        ))}
      </div>
      <div className="controls">
        <div onClick={prevSlide}><img src={iconleft} alt="prev" className='button-icon'/></div>
        <div onClick={nextSlide}><img src={iconright} alt="next" className='button-icon'/></div>
      </div>
      <div className="slider-content">
        <h2>{slides[currentIndex].heading}</h2>
        <p>{spanText(slides[currentIndex].text, slides[currentIndex].spanWords)}</p>
        <Link to="/getquotation" className='button-link'>
        <button className="quote-button">Get Quotation</button>
        </Link>
      </div>
    </div>
  );
}
export default Slider;