import React, { createContext, useState, useContext, useCallback } from 'react';
import CustomToast from './CustomToast';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = useCallback((message) => {
        setToasts((prevToasts) => [...prevToasts, message]);
    }, []);

    const removeToast = useCallback(() => {
        setToasts((prevToasts) => prevToasts.slice(1));
    }, []);

    return (
        <ToastContext.Provider value={addToast}>
            {children}
            {toasts.map((toast, index) => (
                <CustomToast key={index} message={toast} onClose={removeToast} />
            ))}
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};
