import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './Contact.css';
import Contactinfo from '../../components/contact_info/contactinfo';
import Contactform from '../../components/Contactform/Contactform';
const Contact = () => {
  return (
    <div>
      <Navbar/>
      <div className="contact-page">
      <h1 className="contact-page-title heading-large">We’d Love to hear from you</h1>
      <div className="contact-page-content">
        <div className="contact-page-component">
          <Contactform />
        </div>
        <div className="contact-page-component">
          <Contactinfo />
        </div>
      </div>
    </div>
    </div>
  );
};
export default Contact;
