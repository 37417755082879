import React from 'react';
import './WorkAlbum.css';
import seeourwork1 from '../../assets/seeourwork/seeourwork1.png';
import seeourwork2 from '../../assets/seeourwork/seeourwork2.png';
import seeourwork3 from '../../assets/seeourwork/seeourwork3.png';
import seeourwork4 from '../../assets/seeourwork/seeourwork4.png';
import seeourwork5 from '../../assets/seeourwork/seeourwork5.png';
import seeourwork6 from '../../assets/seeourwork/seeourwork6.png';
import seeourwork7 from '../../assets/seeourwork/seeourwork7.png';
import seeourwork8 from '../../assets/seeourwork/seeourwork8.png';
const WorkAlbum = () => {
  return (
    <div className="work-album-container ">
      <h2 className='heading-semibold '>See Our <span className="heading-large">Work Album</span></h2>
      <div className="work-album-scroller">
        <img src={seeourwork1} alt="Completed project" />
        <img src={seeourwork2} alt="Completed project" />
        <img src={seeourwork3} alt="Completed project" />
        <img src={seeourwork4} alt="Completed project" />
        <img src={seeourwork5} alt="Completed project" />
        <img src={seeourwork6} alt="Completed project" />
        <img src={seeourwork7} alt="Completed project" />
        <img src={seeourwork8} alt="Completed project" />
      </div>
    </div>
  )
}

export default WorkAlbum
