import React from 'react';
import './Ourprocesscard.css';

const Ourprocesscard = ({ currentStep }) => {
  return (
    <div className="content">
      <div className="imagecontainer">
        <img src={currentStep.image} alt={currentStep.name} />
        <div className="step-number">{currentStep.num}</div>
      </div>
      <div className="text-box">
        <p>{currentStep.text}</p>
      </div>
    </div>
  );
};

export default Ourprocesscard;
