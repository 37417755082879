import React, { useState } from 'react';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import image1 from '../../assets/seeourwork/seeourwork1.png';
import './OurWork.css';
import { useNavigate } from 'react-router-dom';

const OurWork = () => {
  const [activeTab, setActiveTab] = useState('finished');
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const projects = [
    {id: 1,
      image: require("../../assets/portfolio/Govianu_Madre_Project/17.jpg"),
      address: "Yelhanka Juducial layout",
      clientName: "Govianu Madre Project ",
      floors: "G+3",
      area: "5400 sqft",
      status: "Completed",
      duration: 18,
      beforeImage: require("../../assets/portfolio/Govianu_Madre_Project/0.jpg"),
      afterImage: require("../../assets/portfolio/Govianu_Madre_Project/17.jpg"),
      projectSummary: "Summary of Project 1",
      journeyAlbum: [
        require("../../assets/portfolio/Govianu_Madre_Project/0.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/1.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/2.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/3.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/4.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/5.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/6.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/7.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/8.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/9.png"),
        require("../../assets/portfolio/Govianu_Madre_Project/10.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/11.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/12.png"),
        require("../../assets/portfolio/Govianu_Madre_Project/13.jpg"),
        require("../../assets/portfolio/Govianu_Madre_Project/14.png"),
        require("../../assets/portfolio/Govianu_Madre_Project/15.png"),
        require("../../assets/portfolio/Govianu_Madre_Project/16.png"),
        require("../../assets/portfolio/Govianu_Madre_Project/17.jpg"),
      ],
    },
    {
      id: 2,
      image: require("../../assets/portfolio/This_Parkview_Project/14.jpeg"),
      address: "Yelhanka SFS colony ",
      clientName: "Parkview Project",
      floors: "G+3",
      area: "2400 sqft",
      status: "Completed",
      duration: 18,
      beforeImage: require("../../assets/portfolio/This_Parkview_Project/1.jpeg"),
      afterImage: require("../../assets/portfolio/This_Parkview_Project/14.jpeg"),
      projectSummary: "Summary of Project 2",
      journeyAlbum: [
        require("../../assets/portfolio/This_Parkview_Project/1.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/2.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/3.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/4.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/5.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/6.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/7.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/8.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/9.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/10.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/11.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/12.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/13.jpeg"),
        require("../../assets/portfolio/This_Parkview_Project/14.jpeg"),
      ],
    },
    {
      id: 3,
      image:  require("../../assets/portfolio/Brigade_orchard_Project/5.jpeg"),
      address: "Devanhalli ",
      clientName: "Brigade orchard",
      floors: "G+2 (interior work)",
      area: "3000sqft",
      status: "Completed",
      duration: 2,
      beforeImage:  require("../../assets/portfolio/Brigade_orchard_Project/1.jpeg"),
      afterImage:  require("../../assets/portfolio/Brigade_orchard_Project/5.jpeg"),
      projectSummary: "Summary of Project 3",
      journeyAlbum: [
        require("../../assets/portfolio/Brigade_orchard_Project/1.jpeg"),
        require("../../assets/portfolio/Brigade_orchard_Project/2.jpeg"),
        require("../../assets/portfolio/Brigade_orchard_Project/3.jpeg"),
        require("../../assets/portfolio/Brigade_orchard_Project/4.jpeg"),
        require("../../assets/portfolio/Brigade_orchard_Project/5.jpeg")],
    },
    {
      id: 4,
      image: require("../../assets/portfolio/Baglur_Interior_Project/5.jpeg"),
      address: "Baglur Yelhanka",
      clientName: "Baglur Project ",
      floors: "G+1",
      area: "1200 sqft",
      status: "Completed",
      duration: 2,
      beforeImage: require("../../assets/portfolio/Baglur_Interior_Project/1.jpeg"),
      afterImage: require("../../assets/portfolio/Baglur_Interior_Project/5.jpeg"),
      projectSummary: "Summary of Project 1",
      journeyAlbum: [ require("../../assets/portfolio/Baglur_Interior_Project/1.jpeg"),
        require("../../assets/portfolio/Baglur_Interior_Project/2.jpeg"),
        require("../../assets/portfolio/Baglur_Interior_Project/3.jpeg"),
        require("../../assets/portfolio/Baglur_Interior_Project/4.jpeg"),
        require("../../assets/portfolio/Baglur_Interior_Project/5.jpeg")],
    },
  ];

  const finishedProjects = projects.filter(project => project.status === 'Completed');
  const ongoingProjects = projects.filter(project => project.status === 'Ongoing');

  const handleProjectClick = (project) => {
    navigate(`/portfolio/${project.id}`, { state: { project } });
  };

  const renderProjects = (projects) => {
    const projectsToRender = showAll ? projects : projects.slice(0, 6);
    return projectsToRender.map((project) => (
      <div
        key={project.id}
        onClick={() => handleProjectClick(project)}
      >
        <ProjectCard
          image={project.image}
          clientName={project.clientName}
          location={project.address}
          floor={project.floor}
          area={project.area}
        />
      </div>
    ));
  };

  const currentProjects = activeTab === 'finished' ? finishedProjects : ongoingProjects;
  const projectsCount = currentProjects.length;

  return (
    <div className="portfolio">
      <div className="portfolio-heading heading-semibold">
        Our Work <span className="heading-large"> Portfolio</span>
      </div>
      <p className="portfolio-text body-large">
        Explore the possibilities to your dream place, have a look at our work
      </p>
      <div className="buttons">
        <button
          className={activeTab === 'finished' ? 'active' : ''}
          onClick={() => {
            setActiveTab('finished');
            setShowAll(false);
          }}
        >
          Finished Project
        </button>
        <button
          className={activeTab === 'ongoing' ? 'active' : ''}
          onClick={() => {
            setActiveTab('ongoing');
            setShowAll(false);
          }}
        >
          Ongoing
        </button>
      </div>
      <div className="cards-container">
        {renderProjects(currentProjects)}
      </div>
      {projectsCount > 6 && (
        <button
          className="view-more primary-button primary-button-51"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'View Less' : 'View More'}
        </button>
      )}
    </div>
  );
};

export default OurWork;
