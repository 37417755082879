import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./ProjectDetail.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ServicePageQuotation from "../../components/ServicePageQuotation/ServicePageQuotation";

const ProjectDetail = () => {
  const location = useLocation();
  const project = location.state?.project || null;
  const [visibleImages, setVisibleImages] = useState(0);
  const albumRef = useRef(null);

  useEffect(() => {
    const updateVisibleImages = () => {
      if (albumRef.current) {
        const albumWidth = albumRef.current.clientWidth;
        const imageWidth = 336;
        setVisibleImages(Math.floor(albumWidth / imageWidth));
      }
    };
    updateVisibleImages();
    window.addEventListener("resize", updateVisibleImages);
    return () => {
      window.removeEventListener("resize", updateVisibleImages);
    };
  }, []);

  if (!project) {
    return <div>Loading project details...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="project-details">
        <div className="project-images">
          <img
            src={project.beforeImage}
            alt="Before"
            className="project-image"
          />
          <img src={project.afterImage} alt="After" className="project-image" />
        </div>
        <div className="project-info">
          <div className="client-info-card">
            <div className="info-head heading-small">{project.clientName}</div>
            <div className="body-large">{project.address}</div>
          </div>
          <div className="client-info-card">
            <div className="info-head heading-small">{project.area}</div>
            <div className="body-large">Area</div>
          </div>
          <div className="client-info-card">
            <div className="info-head heading-small">{project.floors}</div>
            <div className="body-large">Floors</div>
          </div>
          <div className="client-info-card">
            <div className="info-head heading-small">
              {project.duration} months
            </div>
            <div className="body-large">Duration</div>
          </div>
          <button className="status-button heading-small">
            {project.status}
          </button>
        </div>
      </div>
      <div className="journey-album-container">
        <h2 className="heading-semibold journey-album-heading">
          See Our <span className="heading-large">Work Album</span>
        </h2>
        <div className="journey-album-scroller" ref={albumRef}>
          {project.journeyAlbum.map((image, index) => (
            <img
              src={image}
              alt={`Journey step ${index + 1}`}
              key={index}
              className="journey-image"
            />
          ))}
        </div>
        <div className="image-count">
          Images {Math.min(visibleImages, project.journeyAlbum.length)} /{" "}
          {project.journeyAlbum.length}
        </div>
        <div className="project-summary">
          <div className="heading-small">Project Summary</div>
          <p className="body-large">
            {project.clientName} in {project.address} was completed in{" "}
            {project.duration} months. The Project owner praised Mahadev
            Enterprise for their professionalism, attention to detail, and
            seamless project management, stating that their new home exceeded
            all expectations. They highly recommend Mahadev Enterprise for
            turning their dream home into a reality.
          </p>
        </div>
      </div>

      <ServicePageQuotation />
      <Footer />
    </div>
  );
};

export default ProjectDetail;
