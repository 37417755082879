import React from 'react';
import './ProjectCard.css';

const ProjectCard = ({ image, clientName, location, floor, area }) => {
  return (
    <div className="project-card">
      <div className="card-image">
        <img src={image} alt="Project" />
      </div>
      <div className="card-details">
        <div className="row">
          <div className="client-name heading-small">{clientName}</div>
          <div className="floor-area body-mid">{floor} {area}</div>
        </div>
        <div className="row">
          <div className="location body-mid">{location}</div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;

