import React from 'react';
import './OurValues.css';
import Housing from '../../assets/Housing.png';
import Licencing from '../../assets/licencing.png';
import Customerfirst from '../../assets/customer-first.png'
const OurValues = () => {

    return (
      <div className="container">
        <div className="row1">
          <div className="column1">
            <div className="ourValues">Our Values: We are Your </div>
            <div className="oneStopSolution">One Stop Solution</div>
            <div className='yourSolution'>
                You got <span>problems</span>, We got <span>Solution</span>
            </div>
            </div>

          <div className="column2">
            <p>
            At Mahadev enterprise it's our expertise to provide comprehensive solutions tailored to your unique needs. From initial concept to final execution, our team is dedicated to resolving every obstacle that stands between you and your vision.
            </p>
          </div>
        </div>
        <div className='row2'>
          <div className='line'></div>
        </div>
        <div className="row3">
          <div className="image-container" style={{backgroundImage:`url(${Housing})`}}>
            <div className="image-text">
              <h4>Housing Solutions</h4>
              <p>We specialize in constructing residential buildings with top-notch infrastructure and interior finishes. Whether you're facing space limitations, structural concerns, or outdated design elements, our team is equipped with the expertise.</p>
            </div>
          </div>
          <div className="image-container" style={{backgroundImage:`url(${Customerfirst})`}}>
            <div className="image-text">
              <h4>Customer-Centric approach</h4>
              <p>At Mahadev Enterprise, we're dedicated to putting you first every step of the way. Your satisfaction is our priority, and we're committed to providing personalized support and transparent communication throughout your journey with us.</p>
            </div>
          </div>
          <div className="image-container" style={{backgroundImage:`url(${Licencing})`}}>
            <div className="image-text">
              <h4>Government Licencing</h4>
              <p>
              Let us take the stress out of the process with our comprehensive licensing services. Our experts will guide you through every step of the licensing process, ensuring compliance with all municipal standards and regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default OurValues
