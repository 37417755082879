import React from 'react';
import './Footer.css';
import logo from '../../assets/Logo.svg';
import { Link } from 'react-router-dom';
import abhiyantriklogo from '../../assets/Abhiyantrik_logo.png';
import call_logo from '../../assets/fluent_call-16-filled.svg';
import location_logo from '../../assets/carbon_location-filled.svg';

const Footer = () => {
  return (
    <footer className="footer">
        <img className="company-logo" src={logo} alt="Mahadev Enterprise" />
        <div className="slogan-section Heading-mid">
          Design | Construction | Interior
        </div>
        <div className="email-section body-mid">
          <p>
            Email us at <a className="email" href="mailto:Mahadev.blrenterprises@gmail.com">Mahadev.blrenterprises@gmail.com</a>
          </p>
        </div>
        <div className="address-contact-section">
          <div className="footer-address">
            <img src={location_logo} alt="logo" className='address-contact-logo'/>
            <p className="company-address text">1st Floor, 316/11, K.No.62/1,3, Krishna Garden, Kattigenahalli, Yelahanka, Bengaluru, Bengaluru Urban, Karnataka, 560064</p>
          </div>
          <div className="footer-contact">
            <img src={call_logo} alt="logo" className='address-contact-logo'/>
            <p className="company-contacts text"> +91 9611783884 | +91 9140012407</p>
          </div>
        </div>
        <div className="links-section">
          <Link className="links" to="/">Home</Link>
          <Link className="links" to="/portfolio">Portfolio</Link>
          <Link className="links" to="/about">About us</Link>
          <Link className="links" to="/services">Services</Link>
          <Link className="links" to="/contact">Contact us</Link>
          <Link className="links" to="/termsOfServices">Terms and conditions</Link>
          <Link className="links" to="/PrivacyPolicy">Privacy Policy</Link>

        </div>
        <div className='footer-bottom'>
          <div className="footer-left">
            <div className="footer-button">
              <Link to="/contact">
                <button className="primary-button primary-button-30 contact-us">Contact Us</button>
              </Link>
              <Link to="/getquotation">
                <button className="primary-button primary-button-30">Get Quotation</button>
              </Link>
            </div>
            <div className="label-1">© 2024 - Mahadev enterprise, abhiyantirk.ai All Rights Reserved</div>
          </div>
          <div className="footer-right">
            <div className='text'>
              <span className='body-small '>Product by:</span>
              <span className='heading-small '>Abhiyantrik.ai</span>
            </div>
            <img src={abhiyantriklogo} alt="Abhiyantik.ai Logo" className="footer-logo"/>
          </div>
        </div>

    </footer>
  );
};

export default Footer;
