import React from 'react';
import './Founder.css';
import shiveshimage from '../../assets/founder/shivesh.jpeg'
import deveshimage from '../../assets/founder/devesh.jpeg'
const Founder = () => {
  return (
    <div className="Foundercontainer">
      <div className="aboutSection">
        <h2>About</h2>
        <h3>Welcome to Mahadev Enterprise</h3>
        <p>
          We are your trusted partner in creating exceptional residential spaces. Established in 2020, we have dedicated ourselves to transforming visions into reality by providing comprehensive construction solutions. With a focus on constructing 3-4 storey buildings and beyond, our expertise lies in delivering top-notch infrastructure and interior finishes that stand the test of time.
        </p>
      </div>
      <div className="foundersSection">
        <div className="founder">
          <div className="founderImageContainer">
            <img src={shiveshimage} alt="Founder 1" className="founderImage" />
            <div className="founderText">Mr. Shivesh Verma</div>
          </div>
        </div>
        <div className="founderDetails">
          <h2>Founders</h2>
          <div className='founderDetails-text'>
            <p>Mahadev Enterprise was founded by Mr. Shivesh Verma and Mr. Devesh Verma with the vision of becoming the leading provider of innovative and sustainable construction solutions, recognized for our commitment to quality and customer satisfaction.
            </p>
          </div>
        </div>
        <div className="founder">
          <div className="founderImageContainer">
            <img src={deveshimage} alt="Founder 2" className="founderImage" />
            <div className="founderText">Mr. Devesh Verma</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Founder;
