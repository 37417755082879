import React, { useEffect, useState } from "react";
import "./CustomToast.css";

const CustomToast = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, 2000);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!visible) return null;

  return (
    <div className="custom-toast">
      <div className="toast-message body-mid">{message}</div>
      <div className="custom-toast-progress"></div>
    </div>
  );
};

export default CustomToast;
