import React from "react";
import "./contactinfo.css";
import call_logo from "../../assets/icons/mingcute_phone-call-fill.svg";
import email_logo from "../../assets/icons/ic_round-email.svg";
import location_logo from "../../assets/icons/vaadin_office.svg";
const Contactinfo = () => {
  const whatsappNumber1 = "+919611783884";
  const whatsappNumber2 = "+919140012407";
  return (
    <div className="contact-us-info">
      <div className="contact-us-info-item">
        <div className="contact-logo">
          <img src={call_logo} alt="logo" />
        </div>
        <div className="conatct-info-detail">
          <div className="heading-small">Call us</div>
          <div className="body-mid">
            <a href={`https://wa.me/${whatsappNumber1}`} target="_blank" rel="noopener noreferrer">{whatsappNumber1}</a><br />
            <a href={`https://wa.me/${whatsappNumber2}`} target="_blank" rel="noopener noreferrer">{whatsappNumber2}</a>
          </div>
        </div>
      </div>
      <div className="contact-us-info-item">
        <div className="contact-logo">
          <img src={location_logo} alt="logo" />
        </div>
        <div className="conatct-info-detail">
          <div className="heading-small">Address</div>
          <div className=" conatct-info-detail-address">
            <span>
              1st Floor, 316/11, K.No.62/1,3, Krishna Garden, Kattigenahalli,
              Yelahanka, Bengaluru, Bengaluru Urban, Karnataka, 560064
            </span>
          </div>
        </div>
      </div>
      <div className="contact-us-info-item">
        <div className="contact-logo">
          <img src={email_logo} alt="logo" />
        </div>
        <div className="conatct-info-detail">
          <div className="heading-small">Email</div>
          <div className="conatct-info-detail-address">
            <span>Mahadev.blrenterprises@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactinfo;
