
import React, { useState } from 'react';
import ServicesCard from '../ServicesCard/ServicesCard';
import './Service.css';
import ExploreServices from '../ExploreServices/ExploreServices';
// import Modal from '';
import ServiceModel from '../ServiceModal/ServiceModal';
import Barbending from '../../assets/service/Barbending.png';
import Block_work from '../../assets/service/Block_work.png';
import Electrical from '../../assets/service/Electrical.png';
import Fabrication from '../../assets/service/Fabrication.png';
import wooden_carpenter from '../../assets/service/wooden_carpenter.png';
import flooring from '../../assets/service/flooring.png';
import Soil_removing from '../../assets/service/Soil_removing.png';
import plumbing from '../../assets/service/plumbing.png';
import plastering from '../../assets/service/plastering.png';
import Interior from '../../assets/service/Interior.png';
import wall_painting from '../../assets/service/wall_painting.png';
import centring from '../../assets/service/centring.jpg';


const serviceData = [
  { image: Soil_removing, text: 'Soil removing (footing)' }, 
  { image: Barbending, text: 'Barbending' },
  { image: Electrical, text: 'Electrical' },
  { image: Fabrication, text: 'Fabrication' },
  { image: wooden_carpenter, text: 'Wooden Carpenter' },
  { image: flooring, text: 'Flooring' },
  { image: wall_painting, text: 'Painting' },
  { image: Block_work, text: 'Block work' },
  { image: plumbing, text: 'Plumbing' },
  { image: plastering, text: 'Plastering' },
  { image: Interior, text: 'Interior' },
  { image: centring, text: 'Centring' },
];

const Service = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({ image: '', text: '' });

  const handleCardClick = (image, text) => {
    setSelectedService({ image, text });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="services-container">
      <ExploreServices/>
      {serviceData.map((service, index) => (
        <ServicesCard key={index} image={service.image} text={service.text} onClick={handleCardClick} />
      ))}
      <ServiceModel 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        image={selectedService.image} 
        service={selectedService.text} 
      />
    </div>
  );
};

export default Service;
