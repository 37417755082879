import React, { useState } from "react";
import { useToast } from "../Toast/ToastContext";
import "./Quotationform.css";

const serviceOptions = [
  "Soil removing (footing)",
  "Barbending",
  "Electrical",
  "Fabrication",
  "Wooden Carpenter",
  "Flooring",
  "Painting",
  "Block work",
  "Plumbing",
  "Plastering",
  "Interior",
  "Centring",
];
const Quotationform = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    services: "",
    plot: "",
    location: "",
    message: "",
    agree: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addToast = useToast();
  
  const handleChange = (event) => {
    const { id, value, type, checked   } = event.target;
    if (id === "phone" && value.length > 10) return;

    if (id === "name") {
      setFormData({
        ...formData,
        [id]: value.replace(/[^a-zA-Z\s]/g, ""),
      });
    } else {
      setFormData({
        ...formData,
        [id]: type === "checkbox" ? checked : value,
        
      });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.agree) {
      addToast("You must agree to the terms of services and privacy policy.");
      return;
    }

    setIsSubmitting(true);

    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/quotation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      addToast(
        "Thank you for your submission! Your quotation request has been successfully received."
      );
    } catch (error) {
      console.error("Error:", error);
      addToast("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

 

  return (
    <div className="Quotation-form">
      <form onSubmit={handleSubmit}>
        <div className="Quotation-form-row">
          <div className="Quotation-form-group">
            <label htmlFor="name">Full Name</label>
            <input
              className="input"
              type="text"
              id="name"
              placeholder="Your Full Name"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="Quotation-form-group">
            <label htmlFor="phone">Contact Number</label>
            <input
              className="input"
              type="number"
              id="phone"
              placeholder="Your Phone Number"
              required
              value={formData.phone}
              onChange={handleChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="Quotation-form-row">
          <div className="Quotation-form-group">
            <label htmlFor="email">Email</label>
            <input
              className="input"
              type="email"
              id="email"
              placeholder="Your Email Address"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          {/* <div className="Quotation-form-group">
                        <label htmlFor="services">Service</label>
                        <input
                            className="input"
                            type="text"
                            id="services"
                            placeholder="Service"
                            value={formData.services}
                            onChange={handleChange}
                        />
                    </div> */}
          <div className="Quotation-form-group">
            <label htmlFor="services">Service</label>
            <select
              id="services"
              value={formData.services}
              onChange={handleChange}
              className="input"
              required
            >
              <option value="">Select a Service</option>
              {serviceOptions.map((service, index) => (
                <option key={index} value={service}>
                  {service}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="Quotation-form-row">
          <div className="Quotation-form-group">
            <label htmlFor="plot">Plot Size</label>
            <input
              className="input"
              type="text"
              id="plot"
              placeholder="e.g. 1500 sq ft"
              value={formData.plot}
              onChange={handleChange}
            />
          </div>
          <div className="Quotation-form-group">
            <label htmlFor="location">Location</label>
            <input
              className="input"
              type="text"
              id="location"
              placeholder="Enter address here"
              value={formData.location}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="Quotation-form-row">
          <div className="Quotation-form-group">
            <label htmlFor="message">Message</label>
            <input
              className="message"
              type="text"
              id="message"
              placeholder="Add your enquiry description"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="Quotation-form-row form-row-agree">
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="agree"
              required
              checked={formData.agree}
              onChange={handleChange}
            />
            <label htmlFor="agree">
              I have read all the{" "}
              <a className="links" href="/termsOfServices">
                Terms of Services and Privacy Policy
              </a>
            </label>
          </div>
          <button
            type="submit"
            className="button primary-button primary-button-51"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Get Quotation"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Quotationform;
