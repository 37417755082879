import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ServicesCard from '../ServicesCard/ServicesCard';
import ServiceModel from '../ServiceModal/ServiceModal';
import './Ourservices.css';
import iconleft from '../../assets/icon-park-solid_left.svg';
import iconright from '../../assets/icon-park-solid_right.svg';

import Barbending from '../../assets/service/Barbending.png';
import Block_work from '../../assets/service/Block_work.png';
import Electrical from '../../assets/service/Electrical.png';
import Fabrication from '../../assets/service/Fabrication.png';
import wooden_carpenter from '../../assets/service/wooden_carpenter.png';
import flooring from '../../assets/service/flooring.png';
import Soil_removing from '../../assets/service/Soil_removing.png';
import plumbing from '../../assets/service/plumbing.png';
import plastering from '../../assets/service/plastering.png';
import Interior from '../../assets/service/Interior.png';
import wall_painting from '../../assets/service/wall_painting.png';
import centring from '../../assets/service/centring.jpg';


const services = [
  { image: Soil_removing, text: 'Soil removing (footing)' }, 
  { image: Barbending, text: 'Barbending' },
  { image: Electrical, text: 'Electrical' },
  { image: Fabrication, text: 'Fabrication' },
  { image: wooden_carpenter, text: 'Wooden Carpenter' },
  { image: flooring, text: 'Flooring' },
  { image: wall_painting, text: 'Painting' },
  { image: Block_work, text: 'Block work' },
  { image: plumbing, text: 'Plumbing' },
  { image: plastering, text: 'Plastering' },
  { image: Interior, text: 'Interior' },
  { image: centring, text: 'Centring' },
 
];

const Ourservices = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({ image: '', text: '' });

  const itemsToShow = 5;

  const handleNext = () => {
    if (startIndex + itemsToShow < services.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleCardClick = (image, text) => {
    setSelectedService({ image, text });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="containerOurservice">
      <div>
      <div className="Ourservice-header">
        <div className="heading">
          Our Services
          <div className="headingline"></div>
        </div>
        <div className="navButtons">
          <button onClick={handlePrev} className="navButton">
            <img src={iconleft} alt="←" />
          </button>
          <button onClick={handleNext} className="navButton">
            <img src={iconright} alt="→" />
          </button>
        </div>
      </div>
      <div className="cardsContainer">
        {services.slice(startIndex, startIndex + itemsToShow).map((service, index) => (
          <ServicesCard
            key={index}
            image={service.image}
            text={service.text}
            onClick={() => handleCardClick(service.image, service.text)}
          />
        ))}
      </div>
      <div className="viewAllButtonContainer">
        <Link to='/services'>
          <button className="viewAllButton">View All</button>
        </Link>
      </div>
      <ServiceModel
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        image={selectedService.image}
        service={selectedService.text}
      />
      </div>
    </div>
  );
};

export default Ourservices;
