import React, { useState, useEffect } from "react";
import "./Ourprocess.css";
import icon_build from "../../assets/icons/icon_build.png";
import icon_consulting from "../../assets/icons/icon_consulting.png";
import icon_designing from "../../assets/icons/icon_designing.png";
import icon_licencing from "../../assets/icons/icon_licencing.png";
import icon_management from "../../assets/icons/icon_management.png";
import icon_planning from "../../assets/icons/icon_planning.png";
import Ourprocesscard from "../Ourprocesscard/Ourprocesscard";
import collapser from "../../assets/oi_collapse-up.svg";

import Ourprocess_consulting from "../../assets/OurProcess/Ourprocess_consulting.png";
import Ourprocess_build from "../../assets/OurProcess/Ourprocess_build.png";
import Ourprocess_licencing from "../../assets/OurProcess/Ourprocess_licencing.png";
import Ourprocess_designing from "../../assets/OurProcess/Ourprocess_designing.png";
import Ourprocess_planning from "../../assets/OurProcess/Ourprocess_planning.png";
import Ourprocess_management from "../../assets/OurProcess/Ourprocess_management.png";
const processSteps = [
  {
    icon: icon_consulting,
    name: "Consulting",
    image: Ourprocess_consulting,
    num: 1,
    text: "We start by talking with you. Our team wants to know what you're thinking, what you want, and what you need for your project. Whether it's a new house or a building for work, we're here to help make your ideas real.",
  },
  {
    icon: icon_planning,
    name: "Planning",
    image: Ourprocess_planning,
    num: 2,
    text: "We Strategically Plan to lay the groundwork for a successful project. From conceptualization to detailed project plans, we ensure every aspect is carefully considered and aligned with your goals.",
  },
  {
    icon: icon_licencing,
    name: "Licencing",
    image: Ourprocess_licencing,
    num: 3,
    text: "Navigating the complexities of licensing and permits is seamless with Mahadev Enterprise. We handle all regulatory requirements, ensuring your project meets municipal standards and regulations.",
  },
  {
    icon: icon_designing,
    name: "Designing",
    image: Ourprocess_designing,
    num: 4,
    text: " Our team of talented designers brings your vision to life with innovative and functional designs. From architectural blueprints to interior layouts, we prioritize creativity and functionality in every detail.",
  },
  {
    icon: icon_build,
    name: "Build",
    image: Ourprocess_build,
    num: 5,
    text: "With expert craftsmanship and attention to detail, we bring your project to life. Our skilled construction team ensures quality workmanship and adherence to project timelines, turning plans into reality.",
  },
  {
    icon: icon_management,
    name: "Management",
    image: Ourprocess_management,
    num: 6,
    text: "Throughout the construction journey, we oversee every aspect of the project with precision and care. From procurement to logistics, our dedicated project managers ensure smooth operations and timely delivery.",
  },
];

const Ourprocess = ({ showToggleButton }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showProcessCard, setShowProcessCard] = useState(!showToggleButton);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStepIndex((prevIndex) => (prevIndex + 1) % processSteps.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleIconClick = (index) => {
    setCurrentStepIndex(index);
  };

  return (
    <div className="our-process">
      <div className="ourprocess-heading">
        <div className="heading-toggle">
          <div className="heading-content">
            <div className="heading-text">Our Process</div>
            <div className="heading-line" />
          </div>
          {showToggleButton && (
            // <img
            //   src={collapser}
            //   alt="Toggle"
            //   className="toggle-icon"
            //   onClick={() => setShowProcessCard(!showProcessCard)}
            // />
            <img
              src={collapser}
              alt="Toggle"
              className={`toggle-icon ${showProcessCard ? "rotate" : ""}`}
              onClick={() => setShowProcessCard(!showProcessCard)}
            />
          )}
        </div>
      </div>
      <div className="timeline">
        {processSteps.map((step, index) => (
          <React.Fragment key={index}>
            <div
              className={`step-container ${
                index <= currentStepIndex ? "active" : ""
              }`}
              onClick={() => handleIconClick(index)}
            >
              <div className="step">
                <img
                  src={step.icon}
                  alt={step.name}
                  className={`step-icon ${
                    index <= currentStepIndex ? "active" : ""
                  } ${step.name}`}
                />
                <span
                  className={`step-text ${
                    index <= currentStepIndex ? "active" : ""
                  }`}
                >
                  {step.name}
                </span>
              </div>
            </div>
            {index < processSteps.length - 1 && (
              <div
                className={`dash ${index < currentStepIndex ? "visible" : ""} `}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
      {showProcessCard && (
        <Ourprocesscard currentStep={processSteps[currentStepIndex]} />
      )}
    </div>
  );
};

export default Ourprocess;
