import React, { useState } from "react";
import "./ServiceModal.css";
import cancel_symbol from "../../assets/material-symbols_cancel.svg";
import { useToast } from "../Toast/ToastContext"; 

const ServiceModel = ({ isOpen, onClose, image, service }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: service,
  });
  const [phoneError, setPhoneError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addToast = useToast();
  const maxLength = 10;

  if (!isOpen) return null;

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
      setFormData({
        ...formData,
        [name]: filteredValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setFormData({
        ...formData,
        phone: value,
      });
    }
  };

  const validatePhone = () => {
    if (formData.phone.length !== maxLength) {
      setPhoneError("Phone number must be exactly 10 digits.");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validatePhone()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        addToast('Thank you for your submission! Your query has been successfully received.');
        setFormData({ name: "", phone: "", email: "", service: service });
      } else {
        addToast('An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error("Error:", error);
      addToast('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2 className="Heading-mid">Contact Us</h2>
          <div className="line"></div>
          <button onClick={onClose} className="modal-close-button">
            <img src={cancel_symbol} alt="Close" />
          </button>
        </div>
        <div className="modal-body">
          <div>
            <img src={image} alt={service} className="Service-Image" />
          </div>
          <form className="service-form" onSubmit={handleSubmit}>
            <label>Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              required
              value={formData.name}
              onChange={handleChange}
            />
            <label>Phone Number</label>
            <input
              type="text"
              name="phone"
              placeholder="Enter Number"
              required
              value={formData.phone}
              onChange={handlePhoneChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            {phoneError && <span className="error-message">{phoneError}</span>}
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <label>Service</label>
            <input type="text" name="service" value={service} readOnly />
            <button
              type="submit"
              className="primary-button primary-button-51 modal-submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Book Consultation"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ServiceModel;
