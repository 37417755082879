import React, { useEffect, useState, useRef } from 'react';

const Counter = ({ end, duration }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef();
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, { threshold: 0.1 });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isIntersecting) return;

    let start = 0;
    const finalCount = parseInt(end, 10);
    if (start === finalCount) return;

    let totalDuration = Math.abs(Math.floor(duration));
    let incrementTime = (totalDuration / finalCount) * 1000;

    let timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === finalCount) clearInterval(timer);
    }, incrementTime);

    return () => clearInterval(timer);
  }, [isIntersecting, end, duration]);

  return <p ref={counterRef} className="number">{count}</p>;
};

export default Counter;
