import React from 'react'
import ClientFeedbackCard from '../ClientFeedbackCard/ClientFeedbackCard';
import styles from './ClientFeedback.module.css';
const feedbackData = [
    {
      rating: 4,
      message: "We got to feel the quality of the people and the care that they wanted to build this building with, and we have been absolutely satisfied ever since…The building is coming in on time. It’s of the quality we want it to be. It’s at price we were told it was going to be.",
      name: "Deepika Krishnamurthy",
      service: "Owner of Attur Project"
    },
    {
      rating: 4,
      message: "I’ve been very impressed with Mahadev enterprises performance on this project. They are well-organized and run a clean, efficient job-site…Shivesh  and Devesh  have been great to work with.",
      name: "Durjoy Patranabish",
      service: "Owner of Juducial project"
    },
    {
      rating: 4,
      message: "Mahadev Enterprises Construction offered professional, reliable service throughout the project. They completed our new warehouse to a high standard and we highly recommend them.",
      name: "Swapnil Khotea",
      service: "Owner of Baglur project"
    }
  ];
const ClientFeedback = () => {
  return (
    <div className={styles.clientfeedbackContainer}>
      <div className={styles.textBoxContainer}>
        <div className={styles.heading}>Over 150+ Trusted Happy Clients</div>
        <div className={styles.headingline}></div>
        <div className={styles.subheading}>
          They Choose us, We build them there best. Listen from them
        </div>
      </div>
      <div className={styles.feedbackContainer}>
        {feedbackData.map((feedback, index) => (
          <ClientFeedbackCard
            key={index}
            rating={feedback.rating}
            message={feedback.message}
            name={feedback.name}
            service={feedback.service}
          />
        ))}
      </div>
    </div>
  )
}

export default ClientFeedback
