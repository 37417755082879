import React from 'react';
import './ServicePageQuotation.css';
import image18 from '../../assets/image 18.png';
import { Link } from 'react-router-dom';
const ServicePageQuotation = () => {
  return (
    <div className="ServicePageQuotation-container">
        <div className="ServicePageQuotation-image-column">
          <img src={image18} alt="Placeholder" />
        </div>
        <div className="ServicePageQuotation-text-column">
          <h2 className='heading-semibold itallic' >Get Your <span className='heading-large itallic' >Dream Home</span> Build</h2>
          <Link to="/getquotation"><button className='primary-button primary-button-51 Quotation-button'>Get Quotation</button></Link>
        </div>
    </div>
  )
}

export default ServicePageQuotation
