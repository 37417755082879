import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Portfolio.module.css";
import Portfoliocard from "../Portfoliocard/Portfoliocard";
import iconleft from "../../assets/icon-park-solid_left.svg";
import iconright from "../../assets/icon-park-solid_right.svg";

const portfolio_ = [
  {
    id: 1,
    leftImage: require("../../assets/portfolio/Govianu_Madre_Project/0.jpg"),
    rightImage: require("../../assets/portfolio/Govianu_Madre_Project/17.jpg"),
    address: "Yelhanka Juducial layout",
    clientName: "Govianu Madre Project ",
    floors: "G+3",
    area: "5400 sqft",
    status: "Completed",
    duration: 18,
    beforeImage: require("../../assets/portfolio/Govianu_Madre_Project/0.jpg"),
    afterImage: require("../../assets/portfolio/Govianu_Madre_Project/17.jpg"),
    projectSummary: "Summary of Project 1",
    journeyAlbum: [
      require("../../assets/portfolio/Govianu_Madre_Project/0.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/1.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/2.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/3.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/4.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/5.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/6.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/7.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/8.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/9.png"),
      require("../../assets/portfolio/Govianu_Madre_Project/10.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/11.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/12.png"),
      require("../../assets/portfolio/Govianu_Madre_Project/13.jpg"),
      require("../../assets/portfolio/Govianu_Madre_Project/14.png"),
      require("../../assets/portfolio/Govianu_Madre_Project/15.png"),
      require("../../assets/portfolio/Govianu_Madre_Project/16.png"),
      require("../../assets/portfolio/Govianu_Madre_Project/17.jpg"),
    ],
  },
  {
    id: 2,
    leftImage: require("../../assets/portfolio/This_Parkview_Project/1.jpeg"),
    rightImage: require("../../assets/portfolio/This_Parkview_Project/14.jpeg"),
    address: "Yelhanka SFS colony ",
    clientName: "Parkview Project",
    floors: "G+3",
    area: "2400 sqft",
    status: "Completed",
    duration: 18,
    beforeImage: require("../../assets/portfolio/This_Parkview_Project/1.jpeg"),
    afterImage: require("../../assets/portfolio/This_Parkview_Project/14.jpeg"),
    projectSummary: "Summary of Project 2",
    journeyAlbum: [
      require("../../assets/portfolio/This_Parkview_Project/1.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/2.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/3.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/4.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/5.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/6.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/7.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/8.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/9.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/10.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/11.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/12.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/13.jpeg"),
      require("../../assets/portfolio/This_Parkview_Project/14.jpeg"),
    ],
  },
  {
    id: 3,
    leftImage:  require("../../assets/portfolio/Brigade_orchard_Project/1.jpeg"),
    rightImage:  require("../../assets/portfolio/Brigade_orchard_Project/5.jpeg"),
    address: "Devanhalli ",
    clientName: "Brigade orchard",
    floors: "G+2 (interior work)",
    area: "3000sqft",
    status: "Completed",
    duration: 2,
    beforeImage:  require("../../assets/portfolio/Brigade_orchard_Project/1.jpeg"),
    afterImage:  require("../../assets/portfolio/Brigade_orchard_Project/5.jpeg"),
    projectSummary: "Summary of Project 3",
    journeyAlbum: [
      require("../../assets/portfolio/Brigade_orchard_Project/1.jpeg"),
      require("../../assets/portfolio/Brigade_orchard_Project/2.jpeg"),
      require("../../assets/portfolio/Brigade_orchard_Project/3.jpeg"),
      require("../../assets/portfolio/Brigade_orchard_Project/4.jpeg"),
      require("../../assets/portfolio/Brigade_orchard_Project/5.jpeg")],
  },
  {
    id: 4,
    leftImage:require("../../assets/portfolio/Baglur_Interior_Project/1.jpeg"),
    rightImage: require("../../assets/portfolio/Baglur_Interior_Project/5.jpeg"),
    address: "Baglur Yelhanka",
    clientName: "Baglur Project ",
    floors: "G+1",
    area: "1200 sqft",
    status: "Completed",
    duration: 2,
    beforeImage: require("../../assets/portfolio/Baglur_Interior_Project/1.jpeg"),
    afterImage: require("../../assets/portfolio/Baglur_Interior_Project/5.jpeg"),
    projectSummary: "Summary of Project 1",
    journeyAlbum: [ require("../../assets/portfolio/Baglur_Interior_Project/1.jpeg"),
      require("../../assets/portfolio/Baglur_Interior_Project/2.jpeg"),
      require("../../assets/portfolio/Baglur_Interior_Project/3.jpeg"),
      require("../../assets/portfolio/Baglur_Interior_Project/4.jpeg"),
      require("../../assets/portfolio/Baglur_Interior_Project/5.jpeg")],
  },
];

const Portfolio = ({ heading, subheading, isAlternativeView }) => {
  const [startIndex, setStartIndex] = useState(0);
  const itemsToShow = 3;
  const navigate = useNavigate();

  const handleNext = () => {
    if (startIndex + itemsToShow < portfolio_.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleCardClick = (project) => {
    navigate(`/portfolio/${project.id}`, { state: { project } });
  };

  return (
    <div className={styles.container_portfolio}>
      <div className={styles.header}>
        <div className={styles.heading}>
          {heading}
          {!isAlternativeView && <div className={styles.headingline}></div>}
          {subheading && <p className={styles.heading_text}>{subheading}</p>}
        </div>
        <div className={styles.navButtons}>
          <button onClick={handlePrev} className={styles.navButton}>
            <img src={iconleft} alt="←" />
          </button>
          <button onClick={handleNext} className={styles.navButton}>
            <img src={iconright} alt="→" />
          </button>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {portfolio_
          .slice(startIndex, startIndex + itemsToShow)
          .map((portfolio) => (
            <div key={portfolio.id} onClick={() => handleCardClick(portfolio)}>
              <Portfoliocard
                leftImage={portfolio.leftImage}
                rightImage={portfolio.rightImage}
                address={portfolio.address}
                isAlternativeView={isAlternativeView}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Portfolio;
