import React from 'react'
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs';
import Ourservices from '../components/Ourservices/Ourservices';
import OurValues from '../components/Ourvalues/OurValues';
import Slider from '../components/Slider/Slider';
import Ourprocess from '../components/Ourprocess/Ourprocess';
import Contactus from '../components/Contactus/Contactus';
import Portfolio from '../components/Portfolio/Portfolio';
import ClientFeedback from '../components/ClientFeedback/ClientFeedback';
const Home = () => {
  return (
    <div>
      <Navbar/>
      <Slider/>
      <OurValues/>
      <WhyChooseUs/>
      <Ourprocess showToggleButton={false} />
      <Ourservices/>
      {/* <Portfolio/> */}
      <Portfolio
        heading="Our Portfolio"
        subheading="Have a glance at our work, crafted with expertise for joy of clients"
        isAlternativeView={false}
      />
      <ClientFeedback/>
      <Contactus/>
      <Footer/>
    </div>
  )
}

export default Home
