import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './GetQuotation.css';
import Contactinfo from '../../components/contact_info/contactinfo';
import Quotationform from '../../components/Quotationform/Quotationform';
const GetQuotation = () => {
  return (
    <div>
      <Navbar/>
      <div className="Quotation-page">
      <h1 className="Quotation-page-title heading-large">We’d Love to hear from you</h1>
      <div className="Quotation-page-content">
        <div className="Quotation-page-component">
          <Quotationform/>
        </div>
        <div className="Quotation-page-component">
          <Contactinfo/>
        </div>
      </div>
    </div>
    </div>
  );
};
export default GetQuotation;
