import React from "react";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="Privacy-Policy">
      <div className="Privacy-Policy-heading">Privacy Policy</div>
      <div className="show-detail">
        <div className="detail title-regular">
          <p>
            At Mahadev Enterprise, accessible at [Company Link], one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Mahadev Enterprise and how we use it. If you have
            additional questions or require more information about our Privacy
            Policy, do not hesitate to contact us through email at
            mahadev.blrenterprises@gmail.com.
          </p>
          <p>
            This privacy policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Mahadev Enterprise. This policy
            is not applicable to any information collected offline or via
            channels other than this website. Consent By using our website, you
            hereby consent to our Privacy Policy and agree to its terms.
            Information We Collect The personal information that you are asked
            to provide, and the reasons why you are asked to provide it, will be
            made clear to you at the point we ask you to provide your personal
            information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide. When you register for
            an Account, we may ask for your contact information, including items
            such as name, company name, address, email address, and telephone
            number.
          </p>
          <p>How We Use Your Information </p>
          <p>
            We use the information we collect in various ways, including to:
            Provide, operate, and maintain our website Improve, personalize, and
            expand our website Understand and analyze how you use our website
            Develop new products, services, features, and functionality
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes Send you emails Find and prevent
            fraud
          </p>
          <p>Log Files</p>
          <p>
            Mahadev Enterprise follows a standard procedure of using log files.
            These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The
            information collected by log files includes internet protocol (IP)
            addresses, browser type, Internet Service Provider (ISP), date and
            time stamp, referring/exit pages, and possibly the number of clicks.
            These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.{" "}
          </p>
          <p>Cookies and Web Beacons</p>
          <p>
            Like any other website, Mahadev Enterprise uses ‘cookies'. These
            cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
