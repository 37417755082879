import React, { useState } from "react";
import { useToast } from "../Toast/ToastContext"; 
import "./Contactus.css";
import contactImage from "../../assets/Rectangle 76.png";

const serviceOptions = [
  "Soil removing (footing)",
  "Barbending",
  "Electrical",
  "Fabrication",
  "Wooden Carpenter",
  "Flooring",
  "Painting",
  "Block work",
  "Plumbing",
  "Plastering",
  "Interior",
  "Centring",
];

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addToast = useToast();

  // const handleChange = (event) => {
  //   const { id, value } = event.target;
  //   if (id === "phone" && value.length > 10) return; // Limit phone input to 10 digits
  //   setFormData({
  //     ...formData,
  //     [id]: value,
  //   });
  // };
  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "phone" && value.length > 10) return; 

    if (id === "name") {
      setFormData({
        ...formData,
        [id]: value.replace(/[^a-zA-Z\s]/g, ""),
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.phone.length !== 10) {
      addToast("Phone number must be exactly 10 digits.");
      return;
    }

    setIsSubmitting(true);

    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      addToast("Thank you for your submission! We will get back to you soon.");
    } catch (error) {
      console.error("Error:", error);
      addToast("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNameInput = (event) => {
    const input = event.target.value;
    setFormData({ ...formData, name: input.replace(/[^a-zA-Z\s]/g, "") });
  };

  return (
    <div className="contactUsContainer">
      <div className="contactForm">
        <div className="heading">
          <h2>Contact Us</h2>
          <div className="headingline"></div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="formGroup">
            <label htmlFor="name">Name</label>
            <input
            className="input"
              type="text"
              // onInput={handleNameInput}
              id="name"
              placeholder="Enter Your Name"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label htmlFor="phone">Phone Number</label>
            <input
            className="input"
              type="text"
              id="phone"
              placeholder="Enter Number"
              required
              value={formData.phone}
              onChange={handleChange}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="formGroup">
            <label htmlFor="email">Email</label>
            <input
            className="input"
              type="email"
              id="email"
              placeholder="Enter Email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="formGroup">
            <label htmlFor="service">Service</label>
            <select
            className="input"
              id="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select a Service</option>
              {serviceOptions.map((service, index) => (
                <option key={index} value={service}>
                  {service}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="submitButton"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Book Consultation"}
          </button>
        </form>
      </div>
      <div className="contactImageContainer">
        <div className="contactImageWrapper">
          <img src={contactImage} alt="Contact Us" className="contactImage" />
        </div>
      </div>
    </div>
  );
};

export default Contactus;
