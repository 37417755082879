import React from 'react'
import './ExploreServices.css'
const ExploreServices = () => {
  return (
    <div className="Explore-services-container">
      <h2 className='heading-semibold '>Explore our wide range of <span className="heading-large">Services</span></h2>
      <p>
        We can do it all for you from starch to your dream solution. Our services ensure
        that every detail is properly managed, providing you with a seamless and stress-free
        construction experience. Trust us to turn your vision into reality, from start to finish.
      </p>
    </div>
  )
}

export default ExploreServices
