import React from 'react'
import './PartnershipCard.css'
const PartnershipCard = ({Image}) => {
  return (
    <div className='Partnership-Card'>
      <img src={Image}/>
    </div>
  )
}
export default PartnershipCard
