import React from 'react';
import styles from './ClientFeedbackCard.module.css';
import Icon from '../../assets/Vector.svg'; 
import ratingImage from '../../assets/Group 55064.svg'
const ClientFeedbackCard = ({ rating, message, name, service }) => {


  return (
    <div className={styles.clientFeedbackContainer}>
      <div className={styles.rating}>
        {/* {renderStars()} */}
        <img src={ratingImage} alt="rating" />
      </div>
      <div className={styles.clientName}>{name}</div>
      <div className={styles.clientMessage}>{message}</div>
      <div className={styles.clientService}>{service}</div>
      <div className={styles.iconContainer}>
        <img src={Icon}/>
        <img src={Icon}/>
      </div>
    </div>
  );
};

export default ClientFeedbackCard;
