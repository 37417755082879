
import React, { useState } from 'react';
import logo from '../../assets/Logo.svg';
import mail_logo from '../../assets/mail_logo.svg';
import call_logo from '../../assets/call_logo.svg';
import hamburgerIcon from '../../assets/icons8-hamburger-menu-100.svg';
import './Navbar1.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isNavbarBottomVisible, setIsNavbarBottomVisible] = useState(false);
  const phoneNumber1 = "+919611783884";
  const phoneNumber2 = "+919140012407";

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };
  const toggleNavbarBottom = () => {
    setIsNavbarBottomVisible(!isNavbarBottomVisible);
  };

  const handlePhoneClick = (number) => {
    window.location.href = `tel:${number}`;
  };

  const handleEmailClick = () => {
    if (isMobileDevice()) {
      window.location.href = `mailto:Mahadev.blrenterprises@gmail.com`;
    } else {
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=Mahadev.blrenterprises@gmail.com`, '_blank');
    }
  };
  return (
    <div className="navbar">
      <div className="navbar-top">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Mahadev Enterprise" />
          </Link>
        </div>
        <div className="hamburger-menu" onClick={toggleNavbarBottom}>
          <img src={hamburgerIcon} alt="Menu" />
        </div>
        <div className="contact-container">
          <div className="contact-item">
            <div className="contact-item-image">
              <img src={call_logo} alt="Call Icon" />
            </div>
            <div className="contact-item-detail">
              <span>Call us</span><br />
              <a onClick={() => handlePhoneClick(phoneNumber1)} className="clickable">{phoneNumber1}</a><br />
              <a onClick={() => handlePhoneClick(phoneNumber2)} className="clickable">{phoneNumber2}</a>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-item-image">
              <img src={mail_logo} alt="Mail Icon" />
            </div>
            <div className="contact-item-detail">
              <span>Mail us</span><br />
              <a className="clickable" onClick={handleEmailClick}>Mahadev.blrenterprises@gmail.com</a>
            </div>
          </div>
        </div>
      </div>
      <div className={`navbar-bottom ${isNavbarBottomVisible ? 'show' : ''}`}>
        <Link to="/">Home</Link>
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/about">About us</Link>
        <Link to="/services">Services</Link>
        <Link to="/contact">Contact us</Link>
      </div>
    </div>
  );
};

export default Navbar;
