import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import Portfolio from '../../components/Portfolio/Portfolio';

import OurWork from '../../components/OurWork/OurWork';
const PortfolioPage = () => {
  return (
    <div>
      <Navbar/>
      <OurWork/>
      <Portfolio
        heading="Before and After"
        subheading=""
        isAlternativeView={true}
      />
      <Footer/>
    </div>
  )
}

export default PortfolioPage
