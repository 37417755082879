import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import Founder from '../components/Founder/Founder'
import QualityMTrustedP from '../components/QualityMTrustedP/QualityMTrustedP'
import FAQ from '../components/FAQ/FAQ';
import Ourprocess from '../components/Ourprocess/Ourprocess'
const Aboutus = () => {
  return (
    <div>
      <Navbar/>
      <Founder/>
      
      <Ourprocess showToggleButton={true} />
      <QualityMTrustedP/>
      <FAQ/>
      <Footer/>
    </div>
  )
}

export default Aboutus
