import React, { useState } from "react";
import "./TermsServices.css";

const TermsServices = () => {
  const [activeTab, setActiveTab] = useState("GENERAL SERVICE TERMS");

  const generalConditionsText = (
    <div className="detail title-regular">
      <p>
        1. The work shall be completed within 50 days reckoned from the day of
        issue of work order including period of mobilization.
      </p>
      <p>
        2. The entire work shall be carried out by contractor in accordance with
        the conditions and specifications stipulated in the schedule of
        quantity/quotation and in the best workmanship manner and as per
        standard practice.
      </p>
      <p>
        3. Materials required for satisfactory completion of work shall be of
        best quality, make, grade, as mentioned in quotation and / or conforming
        to Indian standard specification (latest edition), wherever applicable,
        and approved by Engineer In-Charge.
      </p>
      <p>
        4. Contractor to quote the rates including service tax, VAT, Octroi,
        entry tax and all other taxes. Any other charges incurred by contractor
        but not payable as per this work order, will not be paid / reimbursed.
      </p>
      <p>
        5. Any damage to the Institute property will be made good at
        contractor's risk and cost.
      </p>
      <p>
        6. The Institute reserves the right to reject the bad work / or pay for
        the inferior quality work at reduced rates, as will be worked out by
        Institute. The Institute's decision in this regard will be final and
        binding on contractor.
      </p>
      <p>
        7. Space for labour huts will be provided in campus at suitable location
        to contractor. Contractor at his own cost shall build the labour huts
        with proper sanitation as per approval of engineer in charge. The
        labourers engaged by contractor for the said work should strictly follow
        the rules, discipline, and decorum of the Institute and contractor will
        be liable for any loss or damage caused by them to the Institute
        property.
      </p>
      <p>
        8. The Institute will not be held responsible for payment of any
        compensation to the labourers engaged by contractor. Contractor will pay
        all compensations etc. including accident compensations due to his
        labourers.
      </p>
      <p>
        9. Implements / materials required for the work shall be provided by
        contractor. Institute’s property/materials shall not be used by the
        contractor. A suitable penalty shall be charged if contractor fails to
        observe this condition.
      </p>
      <p>
        10. Contractor shall follow all safety rules and security procedure that
        are in force and applicable during execution of work.
      </p>
      <p>
        11. 5% of the bill amount will be retained by the Institute, which will
        be released after completion of defect liability period of 12 months
        against submission of Bank Guarantee for like amount for a total period
        of five years after virtual completion of work.
      </p>
      <p>
        12. The validity of the tender(s) shall be 120 days from the date of
        opening of tender.
      </p>
      <p>
        13. The contractor shall comply with the provisions of all acts,
        statutes, rules, regulations etc., of the central and state governments
        the case may be that may apply to his case. And if necessary get himself
        duly register as require by the said acts, statutes, rules, regulations
        etc.
      </p>
    </div>
  );
  const specialConditionsText = (
    <div className="detail title-regular">
      <p>
        1. Contractor shall submit performance guarantee of waterproofing work
        as per the format given by Engineer In charge. The duration of
        performance Guarantee will be ten years. All defects/ leakages observed
        and intimated to the contractor shall be rectified immediately. In case
        of failure in attending defects by contractor within stipulated period,
        the rectification will be carried out by Institute at cost &
        consequences of the contractor.
      </p>
      <p>
        2. The Institute will not be held responsible for payment of any
        compensation to the labourers engaged by contractor.
      </p>
      <p>
        3. The contractor shall submit the running/final bills. These bills
        shall be paid after proper and thorough scrutiny by the Institute
        Engineer within one month of submission of bill.
      </p>
      <p>
        4. The contractor will not at any time cause or permit any nuisance on
        the site or adjoining areas or do anything, which shall cause
        unnecessary disturbances or inconveniences to the owner, residents,
        guests, students etc.
      </p>
      <p>
        5. Contractor shall obtain necessary gate pass; labour tokens from the
        security officer.
      </p>
      <p>
        6. Contractor shall obtain prior permission for carrying out work on
        Sundays & holidays.
      </p>
      <p>
        7. Method of work, specification, mode of measurement and other details
        wherever not mentioned should be followed as per Indian standard code of
        practice or as per engineer in charge’s instructions.
      </p>
      <p>
        8. Contractor shall intimate in advance and obtain prior approval before
        undertaking any extra items. The rate for such extra items, if approved,
        will be based on calculation of existing market rates.
      </p>
      <p>
        9. If there is any abnormal increase or reduction in quantities shown in
        BOQ no compensation or extra rate shall be paid on this account.
      </p>
      <p>
        10. The owner will be at liberty to cancel some of the items from the
        contract. In such cases, the contractor cannot claim any commission or
        percentage as profit.
      </p>
      <p>
        11. The Contractor shall employ full-time technically qualified staff
        with adequate experience during the execution of the work.
      </p>
      <p>
        12. The quality, time schedule for completion of work and well behavior
        with Client, and engineers of this project shall be essential & must for
        this project.
      </p>
      <p>
        13. This contract shall be deemed to have been made in Mumbai and only
        the court of competent jurisdiction in Mumbai shall have jurisdiction to
        determine any question or dispute arising out of the contract.
      </p>
      <p>
        14. On the completion of the works the contractor shall at his own cost
        clear away and remove from the site all construction material,
        equipments, surplus materials, rubbish and leave the entire site and
        works clean in a workmanship condition to the satisfaction of the
        employer.
      </p>
      <p>
        15. Deduction towards usage of water and electricity at 1 % (each) of
        the total cost of the work will be made from the contractor.
      </p>
      <p>
        16. On receipt of confirmed works order the contractor shall prepare
        detailed works schedule showing all milestones in order to complete the
        work in time and to the satisfaction of Architect/Engineer in charge.
      </p>
      <p>
        17. Contractor to remove all the debris created during the work out of
        campus at his own cost.
      </p>
    </div>
  );
  const generalServiceTermsText = (
    <div className="detail title-regular">
      <p>General</p>
      <p>
        Welcome to Mahadev Enterprise's website! These terms and conditions
        outline the rules and regulations for the use of our website. By
        accessing this website, we assume you accept these terms and conditions.
        Do not continue to use the Mahadev Enterprise website if you do not
        agree to all of the terms and conditions stated on this page.
      </p>
      <p>
        1.Introduction These terms of service govern your use of our website,
        located at [Company Link]. By using our website, you accept these terms
        of service in full. If you disagree with these terms of service or any
        part of these terms of service, you must not use our website.
      </p>
      <p>
        2. License to Use Website Unless otherwise stated, Mahadev Enterprise
        and/or its licensors own the intellectual property rights published on
        this website and materials used on Mahadev Enterprise. All intellectual
        property rights are reserved. You may view and/or print pages from
        [Company Link] for your own personal use subject to restrictions set in
        these terms of service. You must not: 
        <ul className="spaced-list">
          <li>Republish material from our website without prior written consent </li>
          <li>Sell, rent, or sub-license material from our website.</li>
          <li>Reproduce, duplicate, or copy material from our website.</li>
          <li>Redistribute content from Mahadev Enterprise unless the content is specifically made for redistribution.</li>
        </ul>
           
      </p>
      
      <p>
        <p>3. Acceptable Use </p>
        You must not use our website in any way that causes, or may cause,
        damage to the website or impairment of the availability or accessibility
        of the website; or in any way which is unlawful, illegal, fraudulent, or
        harmful, or in connection with any unlawful, illegal, fraudulent, or
        harmful purpose or activity. You must not use our website to copy,
        store, host, transmit, send, use, publish, or distribute any material
        which consists of (or is linked to) any spyware, computer virus, Trojan
        horse, worm, keystroke logger, rootkit, or other malicious computer
        software. You must not conduct any systematic or automated data
        collection activities on or in relation to our website without our
        express written consent. This includes:
        
        <ul className="spaced-list">
          <li>Scraping </li>
          <li>Data mining </li>
          <li>Data extraction </li>
          <li>Data harvesting </li>
          <li>'Framing' (iframes)</li>
          <li> Article 'Spinning'</li>
        </ul>
        
       
      </p>
      <p>
        <p>4. Restricted Access</p>
        Access to certain areas of our website is restricted. Mahadev Enterprise
        reserves the right to restrict access to certain areas of our website,
        or at our discretion, this entire website. We may change or modify this
        policy without notice. If Mahadev Enterprise provides you with a user ID
        and password to enable you to access restricted areas of our website or
        other content or services, you must ensure that the user ID and password
        are kept confidential. You alone are responsible for your password and
        user ID security. Mahadev Enterprise may disable your user ID and
        password at our sole discretion without notice or explanation.
      </p>
      <p>
        <p>5. User Content </p>
        In these terms of service, "your user content" means material (including
        without limitation text, images, audio material, video material, and
        audio-visual material) that you submit to our website, for whatever
        purpose. You grant to Mahadev Enterprise a worldwide, irrevocable,
        non-exclusive, royalty-free license to use, reproduce, adapt, publish,
        translate, and distribute your user content in any existing or future
        media. You also grant to Mahadev Enterprise the right to sub-license
        these rights, and the right to bring an action for infringement
      </p>
    </div>
  );

  return (
    <div className="TermsServices">
      <div className="TermsServices-heading heading-semibold">
        Terms of<span className="heading-large"> Services</span>
      </div>
      <div className="buttons">
        <button
          className={activeTab === "GENERAL SERVICE TERMS" ? "active" : ""}
          onClick={() => setActiveTab("GENERAL SERVICE TERMS")}
        >
          GENERAL SERVICE TERMS
        </button>
        <button
          className={
            activeTab === "GENERAL CONDITIONS OF CONTRACT" ? "active" : ""
          }
          onClick={() => setActiveTab("GENERAL CONDITIONS OF CONTRACT")}
        >
          GENERAL CONDITIONS OF CONTRACT
        </button>
        <button
          className={activeTab === "SPECIAL CONDITIONS" ? "active" : ""}
          onClick={() => setActiveTab("SPECIAL CONDITIONS")}
        >
          SPECIAL CONDITIONS
        </button>
      </div>

      <div className="show-detail">
        {activeTab === "GENERAL CONDITIONS OF CONTRACT" &&
          generalConditionsText}
        {activeTab === "SPECIAL CONDITIONS" && specialConditionsText}
        {activeTab === "GENERAL SERVICE TERMS" && generalServiceTermsText}
      </div>
    </div>
  );
};

export default TermsServices;
