import React, { useState } from 'react';
import './FAQ.css';

const faqs = [
  {
    question: "What does MAHADEV ENTERPRISES Construction cover?",
    answer: "Mahadev Enterprises covers all kinds of Construction, Renovation and Interior projects."
  },
  {
    question: "Why should anybody choose MAHADEV ENTERPRISES?",
    answer: "The MAHADEV ENTERPRISES Construction brand and our team of qualified professionals stand for very high standards of work quality. MAHADEV ENTERPRISES will ensure timely delivery and will manage everything from plan approval to handover. There is extensive warranty post completion as well."
  },
  {
    question: "How do you guarantee hassle-free work?",
    answer: "The work is hassle-free because we maintain transparency at every level, starting from the procuring of material/s, payments to execution.Also, the same will be updated to the client/s on a regular basis.The Quality control team ensures that the quality is not compromised."
  },
  {
    question: "How do we start?",
    answer: "First step would be to meet and understand the requirement and budget. Second, as per requirement, we will suggest design options till we get the final go-ahead from the client. Finally, the materials are decided and then the execution begins. "
  },
  {
    question: "How do you commit on-time completion of project?",
    answer: "We issue project schedule of entire project and monthly schedules to track activity and the same will be monitored by our engineers .The client/s can also review the same. "
  },
  {
    question: "How does the customer receive updates on the status of the site?",
    answer: "Our technically well-equipped team share updates with the Client on a daily basis with the help of images."
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="faq">
      <div className="faq-container">
        <h1>FAQ</h1>
        {/* <div className="headingline"></div> */}
        <div className="faq-grid">
          {faqs.map((faq, index) => (
            <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
              <div className="lore-x-small faq-question" onClick={() => toggleFAQ(index)}>
                <span>{faq.question}</span>
                <span>{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className="body-mid faq-answer">{faq.answer}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
