import React from 'react';
import './WhyChooseUs.css';
import teamIcon from '../../assets/team_icon.svg';
import happyClientIcon from '../../assets/happy_client_icon.svg';
import projectDoneIcon from '../../assets/Project_done_icon.svg';
import projectOngoingIcon from '../../assets/project_ongoing_icon.svg';
import Counter from '../counter/Counter';
const WhyChooseUs = () => {
  return (
    <>
    <div className="why-choose-us">
      <h2>Why Choose Us?</h2>
      <p>Looking for a reason to trust us, Let us tell you why you should choose us</p>
      <div className="reasons">
        <div className="reason">
          <img src={require('../../assets/building_relationship.svg').default} alt="Building Relationship" />
          <h3>Building Relationship</h3>
          <p>Dedication to building lasting connections with clients, providing ongoing support and partnership beyond project completion.</p>
        </div>
        <div className="reason">
          <img src={require('../../assets/transparent_communication.svg').default} alt="Transparent Communication" />
          <h3>Transparent Communication</h3>
          <p>Open and honest communication throughout every stage of the construction process, making informed decisions providing clarity and peace of mind.</p>
        </div>
        <div className="reason">
          <img src={require('../../assets/quality_assurance.svg').default} alt="Quality Assurance" />
          <h3>Quality Assurance</h3>
          <p>Commitment to delivering work of the highest standards, ensuring that every project meets or exceeds client expectations.</p>
        </div>
        <div className="reason">
          <img src={require('../../assets/timely_delivery.svg').default} alt="Timely Delivery" />
          <h3>Timely Delivery</h3>
          <p>Striving to deliver projects on schedule, without compromising on quality, through efficient project management and execution.</p>
        </div>
      </div>
      </div>
      <div className="one-stop-solution">
        <h3>We Are Your One-Stop Construction Solution</h3>
        <p>We're more than just builders - we're your comprehensive construction solution provider. From initial consultations to project completion and beyond, we offer a full suite of services to meet all your construction needs under one roof.</p>
      </div>
      <div className="stats-container">
      <div className="column dark-background">
        <div className="stat-item">
          <img src={teamIcon} alt="Team Experts" className="icon" />
          <div className="text">
            <Counter end={10} duration={1} />
            <p className="description">Team Experts</p>
          </div>
        </div>
        <div className="stat-item">
          <img src={happyClientIcon} alt="Happy Client" className="icon" />
          <div className="text">
            <Counter end={50} duration={1} />
            <p className="description">Happy Client</p>
          </div>
        </div>
      </div>
      <div className="column light-background">
        <div className="stat-item">
          <img src={projectDoneIcon} alt="Project Done" className="icon" />
          <div className="text">
            <Counter end={20} duration={1} />
            <p className="description">Project Done</p>
          </div>
        </div>
        <div className="stat-item">
          <img src={projectOngoingIcon} alt="Project Ongoing" className="icon" />
          <div className="text">
            <Counter end={5} duration={1} /> 
            <p className="description">Project Ongoing</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default WhyChooseUs;
