import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Aboutus from "./pages/Aboutus";
import ServicePage from "./pages/ServicePage";
import Home from "./pages/Home";
import Contact from "./pages/Contact/Contact";
import GetQuotation from "./pages/GetQuotation/GetQuotation";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";
import TermsofServices from "./pages/TermsofServices/TermsofServices";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import FloatingWhatsAppButton from "./components/FloatingWhatsAppButton/FloatingWhatsAppButton";
import { ToastProvider } from "./components/Toast/ToastContext";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "about",
      element: <Aboutus />,
    },
    {
      path: "services",
      element: <ServicePage />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "portfolio",
      element: <PortfolioPage />,
    },
    {
      path: "/portfolio/:projectId",
      element: <ProjectDetail />,
    },
    {
      path: "getquotation",
      element: <GetQuotation />,
    },
    {
      path: "termsOfServices",
      element: <TermsofServices />,
    },
    {
      path: "PrivacyPolicy",
      element: <PrivacyPolicyPage />,
    },
  ]);

  return (
    <>
    <ToastProvider>
      <RouterProvider router={router}></RouterProvider>
      <FloatingWhatsAppButton phoneNumber="+919611783884" />
      </ToastProvider>
    </>
  );
}
export default App;
