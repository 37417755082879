import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import TermsServices from '../../components/TermsServices/TermsServices'
import Footer from '../../components/Footer/Footer'
const TermsofServices = () => {
  return (
    <div>
      <Navbar/>
      <TermsServices/>
      <Footer/>
    </div>
  )
}

export default TermsofServices
